import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import Layout from 'components/Layout'
import image1 from 'images/project-17b.png'
import image2 from 'images/project-17c.png'

const Page = () => (
  <Layout>
    <Helmet
      title="Water, water, everywhere"
      meta={[
        {
          name: 'description',
          content: 'When a local family&rsquo;s lounge floor collapsed, they didn&rsquo;t know what to do and urgently reached out to the Ryhaven Trust for help.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Water, water, everywhere"
      intro="When a local family&rsquo;s lounge floor collapsed, they didn&rsquo;t know what to do and urgently reached out to the Ryhaven Trust for help."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            On the Ryhaven team&rsquo;s first visit to the site, they discovered a large pool of water gathering underneath the house. It became apparent that every time it rained, stormwater collected in this spot. Over time, the moisture had caused the home&rsquo;s particle board floor to delaminate, resulting in sections of the floor sinking underfoot.
          </Paragraph>
          <Paragraph>
            Further investigation revealed problems with existing stormwater channels (due to lack of maintenance) and a blocked drain, preventing the water from properly flowing to the council drain. Being located at the bottom of a hill, meant that the property suffered under heavy rain &ndash;causing excess surface water. A significant challenge for the team was to prevent the stormwater from getting under the house and the solution came in the form of new drainage channels and sumps installed around the structure to control the surface water. Inside the home, treated ply was used to replace a large section of the existing particle floor and a replacement hot water cylinder was installed after the existing one was damaged, having sunk through the rotten floor.
          </Paragraph>
          <Paragraph>
            The family have helped where possible and already repainted the lounge, kitchen and service rooms. The interior of the house is revitalised with new carpet and vinyl throughout. Once the weather improves, the family plan to paint the exterior. We all look forward to some well- deserved sunshine this Christmas.
          </Paragraph>
          <blockquote className={styles.quote}>
            &ldquo;Words cannot express the gratitude our family have for the way Ryhaven and the team have turned our run-down house to a place we can proudly call home... thank you Ryhaven for (the) work you are doing for our less fortunate communities. &ldquo;
            <br /><br />
            Homeowner
          </blockquote>
          <img className={styles.image} src={image1} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image2} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
